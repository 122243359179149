.Modal {
    position: absolute;
    z-index: 500;
    background-color: white;
    width: 70%;
    height: 378px;
    border: 1px solid #ccc;
    border-radius: 16px;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 50%;
    top: 50%;
    margin-left: -35%;
    margin-top: -189px;
    transition: all 0.3s ease-out;
    overflow: scroll;
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        margin-left: -250px;
    }
}

@media (min-height: 400px) and (max-height: 549px) {
    .Modal {
        height: 300px;
        margin-top: -150px;
    }
}

@media (max-height: 400px) {
    .Modal {
        height: 200px;
        margin-top: -100px;
    }
}