* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #424242;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

html {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
.Modal_Modal__3DZJX {
    position: absolute;
    z-index: 500;
    background-color: white;
    width: 70%;
    height: 378px;
    border: 1px solid #ccc;
    border-radius: 16px;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 50%;
    top: 50%;
    margin-left: -35%;
    margin-top: -189px;
    transition: all 0.3s ease-out;
    overflow: scroll;
}

@media (min-width: 600px) {
    .Modal_Modal__3DZJX {
        width: 500px;
        margin-left: -250px;
    }
}

@media (min-height: 400px) and (max-height: 549px) {
    .Modal_Modal__3DZJX {
        height: 300px;
        margin-top: -150px;
    }
}

@media (max-height: 400px) {
    .Modal_Modal__3DZJX {
        height: 200px;
        margin-top: -100px;
    }
}
.Backdrop_Backdrop__wgO89 {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
